import Modal from 'bootstrap/js/dist/modal';
import Toast from 'bootstrap/js/dist/toast';
import Collapse from "./surplex.Collapse.js";
import Tab from "bootstrap/js/dist/tab";
import Tooltip from "bootstrap/js/dist/tooltip";
import $ from "./cashwrapper";
import { isbot } from "isbot";
/* ****************************************
 * jQuery custom selector migration guide:
 *
 * $('.selector[name!="value"]')        $('.selector:not([name="value"])')
 * $('.selector:checkbox')              $('.selector[type=checkbox]')
 * $('.selector:first')                 $('.selector').first()
 * $('.selector:hidden')                $('.selector').filter(SURPLEX.Utils.jqueryHiddenSelector)
 * $('.selector:input')                 $('.selector').find('input, select, textarea, button')
 * $('.selector:visible')               $('.selector').filter(SURPLEX.Utils.jqueryVisibleSelector)
 * $('#selectElement:selected')         $($('#selectElement').get(0).options[$('#selectElement').get(0).selectedIndex])
 * $('.selector:submit')                $('.selector').find('input[type="submit"], button[type="submit"]')
 */

;(function ($,SURPLEX) {
    SURPLEX.App = $.extend({}, {
        isGuest: true,
        isBot: isbot(navigator.userAgent),
        pageLanguage: '',
        pageCurrency: '',
        getArticleUpdates: false,
        csrfCookieName: '_sc',
        loginRedirect: null,
        imagesToRotateClass : '.js-cardProduct__image',
        initialize: function () {
            this.isGuest = this.getIsGuest();
            this.pageLanguage = this.getPageLanguage();
            this.pageCurrency = this.getPageCurrency();
            this.getArticleUpdates = this.registerForArticleUpdates();
            this.frameBreak();
            this.enableConsentLayer();
            this.setFirstVisitTimestampCookie();
            this.showTimeZoneHint();
            this.enablePluginsOnBody();
            this.enableListeners();
            this.enableImageLazyLoad();
            this.changeSVGtoPNGforIE();
            this.initializeImagesRotation();
        },

        initializeLoadEvents: function(){
            this.showAppBanner();
            this.checkWindowHashString();
            this.setActiveTabToCenter();
            this.setMainMenuActiveElements();
            this.showDashboardSalutation();
            this.loadProfileBadges();
            this.loadFlaps();
            this.loadGallery();
            this.backToTop();
            this.showToasts();
            this.enableFbOptOut();
            this.showOldBrowserHint();
            this.enableContentLazyLoad();
            this.enableGetListeners();
            this.addLinkUrlToFlash();
            this.addMobileLanguageSwitch();
            this.addMobileCurrencySwitch();
        },

        showTimeZoneHint: function () {

            let srxTime = document.getElementById('currentTime');
            let usersTimeOffset = new Date().getTimezoneOffset();
            let serverTimeOffset = parseInt(srxTime.getAttribute('data-time-zone'));
            let diffHours =  Math.round(Math.abs(usersTimeOffset - serverTimeOffset) / 60 * 100) / 100;

            if (shouldShowTimeZoneHint()) {
                if (('localStorage' in window) && !SURPLEX.Utils.storage.get('timezonehint') && $('#js-timezone-hint').length === 0) {
                    let hintText = document.getElementById('currentTime').getAttribute('data-confirm-text');
                    $('#srplx-page').find('.navigation').after(
                        $('<div />').attr('class', 'alert alert--header fade show').append(
                            $('<div />').attr('class', 'container').append(
                                $('<div />').attr('id', 'js-timezone-hint').append(
                                    $('<div />').append(
                                        $('<div />').attr('class', 'icon__timezoneSettings'),
                                        $('<div />').append(
                                            $('<span />').html(hintText)
                                        ),
                                    ),
                                    $('<button />').attr({
                                        "data-bs-dismiss": "alert",
                                        id: "return-to-top",
                                        class: "btn btn--close",
                                        role: "button",
                                        type: "button"
                                    }).on('click', function (e) {
                                        SURPLEX.Utils.storage.set('timezonehint', 1);
                                        $(this).closest('.alert').remove();
                                    })
                                )
                            )
                        )
                    );
                }

                let $timeElems = $(".local__timezone:not(.show_hint)");
                if ($timeElems.length > 0) {
                    let usersTimeAhead = usersTimeOffset < serverTimeOffset;
                    let timeHintext = srxTime.getAttribute(usersTimeAhead ? 'data-time-ahead' : 'data-time-behind').replace("%s",
                        diffHours + '' + srxTime.getAttribute('data-translation-h'));
                    $.each($timeElems, function (index, el) {
                        $(el).addClass('show_hint').attr({
                            'title': '<span class="icon__timezoneSettings"></span>' + timeHintext
                        }).on('mouseover touch', function (e) {
                            e.stopPropagation();
                            let settings = {
                                html: true,
                                placement: "top"
                            };
                            let $el = $(this);
                            let toolTip = Tooltip.getInstance($el.get(0)) || new Tooltip($el.get(0), settings);
                            toolTip.show();
                        });
                    });
                }
            }

            function shouldShowTimeZoneHint() {
                return SURPLEX.App.isBot === false && diffHours !== 0;
            }
        },

        showAppBanner : function () {
            if (/(android)/i.test(navigator.userAgent)
                && window.location.search.indexOf("hide-cookie-settings") < 0
                && SURPLEX.Utils.getCookie('surplex_apphint') === null
                && SURPLEX.App.isBot === false
            ) {
                let $appMeta = $('#appstore');
                let $bannerWrapper = $('<div/>').attr({
                    class: "smartAppBanner smartAppBanner--show",
                    role: "button"
                }).append(
                    $('<a/>').attr({
                        class: "smartAppBanner__close",
                        tabindex: "0",
                        'aria-label' : "close",
                        role: "button"
                    }).html('<span class="icon__close"></span>').on('click', function (e) {
                        e.preventDefault();
                        $bannerWrapper.hide();
                        SURPLEX.Utils.setCookie('surplex_apphint', 1, (30 * 24 * 60 * 60 * 1000));
                    }),
                    '<img src="https://res.surplex.com/assets/icon__app_small.png" width="52" height="52" alt="' + $appMeta.data("title") + '">' +
                    '<span><span class="smartAppBanner__title">' + $appMeta.data("title") + '</span>' +
                    '<span class="smartAppBanner__subline">' + $appMeta.data("subline") + '</span></span>',
                    $('<a/>').attr({
                        class: "btn btn--tertiary btn--small",
                        href: "https://play.google.com/store/apps/details?id=de.objectcode.surplex2",
                        target: "_blank"
                    }).text($appMeta.data("button-label")),
                );
                $('#srplx-page').prepend($bannerWrapper);
            }

        },

        setMainMenuActiveElements: function(){
            let googlePageType = $('meta[name=srxGooglePageType]').attr('content');
            if (googlePageType === 'auctionCalendar' || googlePageType === 'auction') {
                $('#js-auction-nav-item').addClass('is-active')
            }
            if (googlePageType === 'directSales') {
                $('#js-direct-sale-nav-item').addClass('is-active')
            }
            if (googlePageType === 'marketplace') {
                $('#js-marketplace-nav-item').addClass('is-active')
            }
            if (googlePageType === 'category') {
                let mainCategoryId = SURPLEX.Track.getDataFromLayer('dataLayer', 'mainCategoryId');
                if (typeof mainCategoryId !== 'undefined') {
                    $('#navbarSupportedContent')
                        .find('[data-category-id="' + mainCategoryId + '"]')
                        .addClass('is-active')
                        .closest('.mainNav--item')
                        .addClass('is-active');
                }
            }
        },

        showDashboardSalutation: function () {
            let $e = $('.js-dashboard-salutation');
            if($e.length > 0){
                let $hour = new Date().getHours();
                let $salutation = $e.data('salutation-evening');
                if($hour < 12){
                    $salutation = $e.data('salutation-morning');
                }else if($hour < 18){
                    $salutation = $e.data('salutation-day');
                }
                $('.js-dashboard-salutation').html($salutation);
            }
        },

        getIsGuest: function () {
            return $('#loginModal').length > 0;
        },

        registerForArticleUpdates: function () {
            return ($('.js-machine--details.js-article--item').length > 0) ||
                (!SURPLEX.App.getIsGuest() && $('.js-article-watch--item .js-end-date[data-initialized]').length > 0);
        },

        setFirstVisitTimestampCookie: function () {
            if(SURPLEX.Utils.getCookie('surplex_source') === null &&
                SURPLEX.Utils.getCookie('surplex_first_visit') === null){
                SURPLEX.Utils.setCookie('surplex_first_visit', new Date().toISOString());
            }
        },

        enableConsentLayer: function () {
            if (shouldShowLayer()) {
                new Modal(document.getElementById('consentModal'), {
                    backdrop: 'static',
                    keyboard: false
                }).show();
                $('#srplx-page').append('<img src="/assets/fixed/cl/shown.gif" alt="cl" width="1" height="1" />');
                if( window.location.search.indexOf("show-cookie-settings") > -1){
                    $('.js-consentText').addClass('d-none');
                    $('.js-cookieSettings').removeClass('d-none');
                }
            }
            function shouldShowLayer(){
                let shownModal = Modal.getInstance(document.getElementById('consentModal'));
                if(shownModal !== null || window.location.search.indexOf("hide-cookie-settings") > -1){
                    return false;
                }
                return window.location.search.indexOf("show-cookie-settings") > -1 || (
                        SURPLEX.Utils.getCookie('bcConsent') === null ||
                        SURPLEX.Utils.getCookie('bcConsent').indexOf("cf") < 0
                    ) && SURPLEX.App.isBot === false && $("#consentModal").data('hide-consent-layer') === 0
            }
        },
        enablePluginsOnBody: function () {
            SURPLEX.Utils.enablePlugInsForSection($('body'));
        },

        enableListeners: function () {
            $(window).on('hashchange', function () {
                SURPLEX.App.checkWindowHashString();
            });

            SURPLEX.App.uniqueBindEvent('[data-pdf-track]', 'click', 'apptrack', function (e) {
                SURPLEX.Track.trackGA(
                    'downloadTracking',
                    $('meta[name=srxGooglePageType]').attr('content'),
                    'download_' +  $(this).data('pdf-track')
                );
            });

            SURPLEX.App.uniqueBindEvent('.remember', 'click', 'app', function (e) {
                try {
                    if (typeof $(this).attr('data-remember-name') !== 'undefined') {
                        let $expire = undefined;
                        if (typeof $(this).attr('data-remember-expire') !== 'undefined') {
                            $expire = parseInt($(this).attr('data-remember-expire'))
                        }
                        SURPLEX.Utils.setCookie($(this).attr('data-remember-name'), $(this).attr('data-remember-val'), $expire);
                    }
                } catch (e){}

                if ($(this).is('.js-credit-card-expiry')) {
                    try {
                        let alert = $('#creditCardExpiryNotice');
                        if(alert.length > 0) {
                            if (typeof alert.attr('data-is-30day-notice') !== 'undefined' && alert.attr('data-is-30day-notice') === '1') {
                                SURPLEX.Payment.CreditCardExpiry.setCreditCard30NoticeClosed();
                            }
                            if (typeof alert.attr('data-is-0day-notice') !== 'undefined' && alert.attr('data-is-0day-notice') === '1') {
                                SURPLEX.Payment.CreditCardExpiry.setCreditCard0NoticeClosed();
                            }
                        }
                    } catch (e) {}
                }
            });

            SURPLEX.App.enableDataRedirect();

            SURPLEX.App.uniqueBindEvent('[data-ajax]', 'click', 'app', function (e) {
                const url = $(this).data('ajax');
                const params = $(this).data('ajax-params');
                const destId = $(this).data('ajax-dest-id');

                SURPLEX.Ajax.fetch({
                    url: url,
                    data: params,
                    targetObj : $(destId),
                    loadingTarget : $(destId)
                });
            });

            SURPLEX.App.uniqueBindEvent('.js-translate-description', 'click', 'app', function (e) {
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {articleId: $(this).data('article-id')},
                        callbackEnd : $.extend(
                            {

                                'translationLoaded': function (target, data) {
                                    $('.js-translate-description').remove();
                                    $('#translated-description-text').html(data);
                                    $('#translated-description').removeClass('d-none');
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });


            SURPLEX.App.uniqueBindEvent('.js-headerMenu', 'show.bs.collapse', 'app', function (e) {
                $('html').addClass('headerMenu-is-show');

            });
            SURPLEX.App.uniqueBindEvent('.js-headerMenu', 'hide.bs.collapse', 'app', function (e) {
                $('html').removeClass('headerMenu-is-show');
            });
            SURPLEX.App.uniqueBindEvent('.js-currencySwitch', 'click', 'app', function (e) {
                SURPLEX.Track.trackGA('currencySelector', $('meta[name=srxGooglePageType]').attr('content'), 'currencyModal_opened');
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        callbackEnd : $.extend(
                            {
                                'settingsOpened': function (targetObject, res) {
                                    if (res.hasOwnProperty('success') && res.success === true) {
                                        if (res.hasOwnProperty('currencySettings')) {
                                            $('#currencySwitch').remove();
                                            $(res.currencySettings).appendTo("body");
                                            let $modalContent = document.getElementById('currencySwitch');
                                            let $modal = new Modal($modalContent);
                                            $modalContent.addEventListener('shown.bs.modal', function(){
                                                SURPLEX.App.initialize();
                                            });
                                            $modal.show();
                                        }
                                        if (res.hasOwnProperty('currencyConfirm')) {
                                            $('#currencyConfirm').remove();
                                            $(res.currencyConfirm).appendTo("body");
                                            let $modalContent = document.getElementById('currencyConfirm');
                                            let $modal = new Modal($modalContent);
                                            $modalContent.addEventListener('shown.bs.modal', function(){
                                                SURPLEX.App.initialize();
                                            });
                                            //$modal.show();
                                        }
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }                );
            });

            SURPLEX.App.uniqueBindEvent('#currency-selection', 'change', 'app', function (e) {
                if($(this).val() === 'EUR') {
                    $('.js-currencyStatus').addClass('d-none');
                } else {
                    $('.js-exchange-rate').text($($(this).get(0).options[$(this).get(0).selectedIndex]).data('exchange-rate'));
                    $('.js-exchange-date').text($($(this).get(0).options[$(this).get(0).selectedIndex]).data('exchange-date'));
                    $('.js-currencyStatus').removeClass('d-none');
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-currencyStatusReset', 'click', 'register', function (e) {
                $("#currency-selection").val("EUR").change();
            });

            SURPLEX.App.uniqueBindEvent('#currencyConfirmCB', 'change', 'app', function (e) {
               if($(this).is(':checked')) {
                   SURPLEX.Track.trackGA('currencySelector', $('meta[name=srxGooglePageType]').attr('content'), 'currencyConfirm_checked');
                   $('#modal-submit-currencyConfirm').removeAttr('disabled');
               } else{
                   $('#modal-submit-currencyConfirm').attr('disabled', 'true');
               }
            });

            SURPLEX.App.uniqueBindEvent('#modal-submit-currencySwitch, #modal-submit-currencyConfirm', 'click', 'register', function (e) {
                let $selectedCurrency = $('#currency-selection').val();

                if($selectedCurrency === this.pageCurrency){
                    Modal.getInstance($(this).closest('.modal.show').get(0)).hide();
                    return;
                }
                if($(this).data('show-confirm') == true){
                    SURPLEX.Track.trackGA('currencySelector', $('meta[name=srxGooglePageType]').attr('content'), 'currencyConfirm_opened');
                    Modal.getInstance($(this).closest('.modal.show').get(0)).hide();
                    new Modal(document.getElementById('currencyConfirm')).show();
                    return;
                }
                SURPLEX.Track.trackGA(
                    'currencySelector',
                    $('meta[name=srxGooglePageType]').attr('content'),
                    'from_' + SURPLEX.App.pageCurrency + ' to_' + $selectedCurrency
                );

                SURPLEX.Utils.setCookie('surplex_currency', $selectedCurrency);

                if(SURPLEX.App.getIsGuest()){
                    window.location.reload();
                }
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {
                            selectedCurrency: $selectedCurrency,
                        },
                        callbackEnd : $.extend(
                            {
                                'settingsSaved': function (targetObject, data) {
                                    if (data.hasOwnProperty('success') && data.success === true) {
                                        window.location.reload();
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });

            SURPLEX.App.uniqueBindEvent('.js-inspectionCalendar', 'click', 'app', function (e) {
                SURPLEX.Track.trackGA('inspectionCalendar', $('meta[name=srxGooglePageType]').attr('content'), 'inspectionCalendar_opened');
                SURPLEX.Ajax.post(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        callbackEnd : $.extend(
                            {
                                'inspectionCalendar': function (targetObject, res) {
                                    if (res.hasOwnProperty('success') && res.success === true) {
                                        $('#inspectionCalendarStep1').remove();
                                        $('#inspectionCalendarStep2').remove();
                                        $('#inspectionCalendarStep3').remove();
                                        $(res.html).appendTo("body");
                                        let $modalContent = document.getElementById('inspectionCalendarStep1');
                                        let $modal = new Modal($modalContent);
                                        $modalContent.addEventListener('shown.bs.modal', function(){
                                            SURPLEX.App.initialize();
                                        });
                                        $modal.show();
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });

            SURPLEX.App.uniqueBindEvent('.js-inspectionCalendarSlots', 'click', 'app', function (e) {
                $('.js-inspectionCalendarSlots').removeClass('viewingCalendarSlot__dateItem--selected');
                $(this).addClass('viewingCalendarSlot__dateItem--selected');
                $('.js-inspectionCalendarContinue').removeAttr('disabled', false);
            });

            SURPLEX.App.uniqueBindEvent('.js-inspectionCalendarContinue', 'click', 'app', function (e) {
                SURPLEX.Ajax.post(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {slot: $('.js-inspectionCalendarSlots.viewingCalendarSlot__dateItem--selected').data('slot')},
                        callbackEnd : $.extend(
                            {
                                'inspectionCalendar': function (targetObject, res) {
                                    if (res.hasOwnProperty('success') && res.success === true) {
                                        $('#inspectionCalendarStep2').remove();
                                        Modal.getInstance($('#inspectionCalendarStep1').get(0)).hide();
                                        $(res.html).appendTo("body");
                                        let $modalContent = document.getElementById('inspectionCalendarStep2');
                                        let $modal = new Modal($modalContent);
                                        $modalContent.addEventListener('shown.bs.modal', function(){
                                            SURPLEX.App.initialize();
                                        });
                                        $modal.show();
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });

            SURPLEX.App.uniqueBindEvent('.js-inspectionCalendarFinish', 'click', 'app', function (e) {
                SURPLEX.Ajax.post(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {
                            slot: SURPLEX.Utils.decodeBase($(this).data('slot')),
                            cnt_of_visitors: $('#inspection_calendar_cnt_of_visitors').val(),
                            comment: $('#inspection_calendar_comment').val(),
                        },
                        callbackEnd : $.extend(
                            {
                                'inspectionCalendar': function (targetObject, res) {
                                    if (res.hasOwnProperty('success') && res.success === true) {
                                        $('#inspectionCalendarStep3').remove();
                                        if($('#inspectionCalendarStep2').length > 0) {
                                            SURPLEX.Track.trackGA('inspectionCalendar', $('meta[name=srxGooglePageType]').attr('content'), 'inspectionCalendar_success');
                                            Modal.getInstance($('#inspectionCalendarStep2').get(0)).hide();
                                        }
                                        $('.js-inspectionCalendar').attr('disabled', true);

                                        $(res.html).appendTo("body");
                                        let $modalContent = document.getElementById('inspectionCalendarStep3');
                                        let $modal = new Modal($modalContent);
                                        $modalContent.addEventListener('shown.bs.modal', function(){
                                            SURPLEX.App.initialize();
                                        });
                                        $modal.show();
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });


            SURPLEX.App.uniqueBindEvent('button[data-bs-toggle="tab"]', 'shown.bs.tab', 'app', function (e) {
                let url = location.href.replace(/\/$/, '');
                history.replaceState(null, null, url.split('#')[0] + $(this).data('bs-target'));
                if(SURPLEX.Utils.isMobileViewport()){
                    SURPLEX.Utils.scrollTo($(this).data('bs-target'));
                }
            });

            SURPLEX.App.uniqueBindEvent('button[data-bs-toggle="tab"]', 'hide.bs.tab', 'app', function (e) {
                let $formInTab = $($(this).data('bs-target')).find('form').not('#user-password-form');
                if ($formInTab.length === 1 && $formInTab.data('formtype') === 'editProfile') {
                    let formValidator = SURPLEX.Register.validator[$formInTab.data('action')];
                    let $badge = $(this).find('.user__statusBadge');
                    if ($badge.length > 0) {
                        $badge.remove();
                    }
                    if (!formValidator.form()) {
                        $(this).append(
                            $('<span>')
                                .attr({'class': 'user__statusBadge'})
                                .text(formValidator.errorList.length));
                    }
                    let modified = $formInTab.serialize() !== $formInTab.data('serialized');
                    if(modified){
                        e.preventDefault();
                        // e.target -> previous active tab // e.relatedTarget -> newly activated tab
                        let $tabWrapper = $(e.target.parentNode);
                        $.fn.surplexModal({
                            title: $tabWrapper.data('confirm-title'),
                            content: $("<div />").attr('class', 'col-sm-12').text($tabWrapper.data('confirm-copy')),
                            closeIcon: true,
                            buttons: [
                                {
                                    text:  $tabWrapper.data('btn-discard'),
                                    id: SURPLEX.Utils.createUUID(),
                                    classname: 'btn-light',
                                    click: function ($modal) {
                                        $formInTab.get(0).reset();
                                        $formInTab.trigger('change');
                                        $modal.dismiss();
                                    }
                                },
                                {
                                    text:  $tabWrapper.data('btn-confirm'),
                                    id: SURPLEX.Utils.createUUID(),
                                    classname: 'btn-success',
                                    click: function ($modal) {
                                        $modal.dismiss();
                                        $formInTab.trigger('submit', { cancelable: true});
                                    }
                                }
                            ]
                        });
                    }
                }
            });


            $('.ddMultiSelect--item .form-check').off('click').on('click', function (e) {
                let $target = $(e.target);
                if($target.data('toggle') === 'collapse' || $target.parent().data('toggle') === 'collapse' && !$target.is('input') && !$target.is('label')){
                    if($target.data('toggle') === 'collapse'){
                        $($target.attr('data-target')).collapse('toggle');
                    } else {
                        $($target.parent().attr('data-target')).collapse('toggle');
                    }
                }
                if (!($target.hasClass("continue") || $target.parents(".continue").length)){
                    e.stopPropagation();
                }

            });

            SURPLEX.App.uniqueBindEvent('#calculator-btn', 'click', 'app', function (e) {
                const $itemDetails = $("div.js-machine--details.js-article--item");
                const articleID = $itemDetails.data('article-id');
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {articleId: articleID},
                        callbackEnd : $.extend(
                            {
                                'calculatorLoaded': function (target, data) {
                                    if (data.hasOwnProperty('html')) {
                                        $("#calculatorModal").remove();
                                            $('#srplx-page').append(data.html);
                                        new Modal(document.getElementById('calculatorModal')).show();
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });

            SURPLEX.App.uniqueBindEvent('.js-article-address', 'click', 'app', function (e) {
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        data: {address: $(this).data('address')},
                        callbackEnd : $.extend(
                            {
                                'googleMapsLoaded': function (target, data) {
                                    $('#googleMaps').remove();
                                    $('#srplx-page').append(data);
                                    let newgMapEl = document.getElementById('googleMaps');
                                    if(newgMapEl){
                                        let mapsModal = Modal.getOrCreateInstance(newgMapEl);
                                        if(mapsModal){
                                            mapsModal.show();
                                        }
                                    }
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
                return false;
            });

            SURPLEX.App.uniqueBindEvent('.js-multiple-addresses', 'click', 'app', function (e) {
                e.preventDefault();
                e.stopPropagation();
                let filterVal = SURPLEX.Filter.getCurrentFilter();
                let filterParams = SURPLEX.Utils.rtrim(filterVal, '&');

                let $mapsTrigger = $(this);
                if(typeof window.MarkerClusterer === 'undefined'){
                    let mcScript = document.createElement('script');
                    mcScript.onload = function () {
                        loadMap()
                    };
                    mcScript.defer = true;
                    mcScript.src = "/assets/fixed/maps/markerclustererplus.min.js";
                    document.head.appendChild(mcScript);
                } else {
                    loadMap();
                }
                function loadMap(){
                    SURPLEX.Ajax.fetch(
                        {
                            url: SURPLEX.Utils.decodeBase($mapsTrigger.data('url')),
                            data: {filterString: filterParams},
                            callbackEnd : $.extend(
                                {
                                    'googleMapsLoaded': function (target, data) {
                                        $('#googleMaps').remove();
                                        $('#srplx-page').append(data);
                                        let newgMapEl = document.getElementById('googleMaps');
                                        if(newgMapEl){
                                            let mapsModal = Modal.getOrCreateInstance(newgMapEl);
                                            if(mapsModal){
                                                mapsModal.show();
                                            }
                                        }
                                    }
                                },
                                SURPLEX.Ajax.getDefaultEndCallbacks()
                            ),
                            loadingTarget : $mapsTrigger
                        }
                    );
                }
                return false;
            });

            SURPLEX.App.uniqueBindEvent('.js-dismantling-loading-costs, .js-open-modal-faq', 'click', 'app', function (e) {
                SURPLEX.Ajax.fetch(
                    {
                        url: SURPLEX.Utils.decodeBase($(this).data('url')),
                        callbackEnd : $.extend(
                            {
                                'infoLoaded': function (target, data) {
                                    if (data.hasOwnProperty('html')) {
                                        if (data.hasOwnProperty('lightbox')) {
                                            $('#faq-modal').remove();
                                            $('#srplx-page').append(data.html);
                                            SURPLEX.Bid.toogleBiddingModal('#faq-modal');
                                            new Modal(document.getElementById('faq-modal')).show();
                                        }}
                                }
                            },
                            SURPLEX.Ajax.getDefaultEndCallbacks()
                        ),
                        loadingTarget : $(this)
                    }
                );
            });


            SURPLEX.App.uniqueBindEvent('#passwordModal', 'show.bs.modal', 'app', function (e) {
                SURPLEX.Track.trackGA(
                    "Passwort vergessen",
                    $('meta[name=srxGooglePageType]').attr('content'),
                    'pwdModal_opened',
                    0
                );
            });

            SURPLEX.App.uniqueBindEvent('#req-form', 'show.bs.modal', 'app', function (e) {
                    const loggedIn = SURPLEX.App.getIsGuest() ? 0 : 1;
                    SURPLEX.Track.trackGA("Anfrage", "Speziell", "clicked", loggedIn);
            });

            SURPLEX.App.uniqueBindEvent('.js-copy-link', 'click', 'app', function () {
                navigator.clipboard.writeText(SURPLEX.Utils.decodeBase($(this).data('copy-link'))).then(function() {
                    /* close share button? Show message? ... hmmm  */
                }, function(reason) {
                    /* hide option? disable function? */
                });
            });


            $.each($('.loginform'), function (index, el) {
                let $loginform = $(el);
                SURPLEX.Register.validator[$loginform.data('action')] = $loginform.validate({
                    errorElement: "div",
                    errorClass: "is-invalid",
                    validClass: "",
                    focusInvalid: true,
                    ignore: "input[type=hidden]:not(.forcevalidate)",
                    showErrors: function (errorMap, errorList) {
                        this.errorList = $.grep(errorList, function (error) {
                            return error.element !== undefined;
                        });

                        SURPLEX.FormValidationErrorTracking.parseShowErrorList(
                            this.errorList
                        );

                        this.defaultShowErrors();
                    },
                    submitHandler: function () {
                        $loginform.appendFingerprint();
                        if ($($loginform.data('trigger')).length > 0) {
                            $($loginform.data('trigger')).attr("disabled", "disabled");
                            $loginform.find('.js-statusmessage').removeClass('is-invalid').addClass('is-hidden').html('');
                        }
                        SURPLEX.Ajax.post(
                            {
                                url: SURPLEX.Utils.decodeBase($loginform.data('action')),
                                data: $loginform.serialize(),
                                callbackStart : $.extend({'appendloginData': function () {
                                        SURPLEX.Register.validator[$loginform.data('action')]['serialized'] = $loginform.serialize()
                                    }}, SURPLEX.Ajax.getDefaultStartCallbacks()),
                                callbackEnd : $.extend(
                                    {'loginsubmitted': SURPLEX.App.loginSubmitted},
                                    {'default': SURPLEX.Ajax.getDefaultEndCallbacks().defaultAppFunc}
                                ),
                                loadingTarget : $('#loginform'),
                                callbackOnSuccess : $.extend(
                                    SURPLEX.Ajax.getDefaultSuccessCallbacks(),
                                    {
                                        'onSuccessHandler': function (targetObject, result, loadingTarget) {
                                            if (result.hasOwnProperty('success') && result.success === true) {
                                                SURPLEX.FormValidationErrorTracking.trackSuccess($loginform);
                                            }
                                        }
                                    }
                                ),
                                callbackOnFail : function (jqXHR) {
                                    let res = jqXHR.hasOwnProperty('responseJSON') ? jqXHR.responseJSON :
                                        jqXHR.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(jqXHR.responseText) : jqXHR;
                                    if (res.hasOwnProperty('message')) {
                                        $loginform.find('.js-statusmessage').addClass('is-invalid').removeClass('is-hidden').html(res.message).show();
                                        $loginform.find("input[type='password']").val('');
                                    }
                                    if ($($loginform.data('trigger')).length > 0) {
                                        $($loginform.data('trigger')).removeAttr('disabled');
                                    }
                                    if (res.hasOwnProperty('type')){
                                        SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                                            $loginform.data('formtype'),
                                            'global',
                                            '',
                                            res.type
                                        );
                                    }
                                }
                            }
                        );
                    }
                });
            });

            SURPLEX.App.uniqueBindEvent('#logout', 'click', 'app', function (e) {
                e.preventDefault();
                let $el = $(this);
                if (typeof $el.attr('data-clicked') === "undefined") {
                    $el.attr('data-clicked', true);
                } else {
                    return true;
                }

                SURPLEX.Ajax.fetch({
                        url: SURPLEX.Utils.decodeBase($el.data('target')),
                        callbackStart : {
                            'loading' : function (){
                                $el.addClass('is-loading');
                            }
                        },
                        callbackEnd : {
                            'loggedout': function (target, data) {
                                window.location.reload(true);
                            }
                        },
                        callbackOnFail: {
                            'loggedout': function (target, data) {
                                window.location.reload(true);
                            }
                        },
                        loadingTarget : undefined
                    });
            });

            SURPLEX.App.uniqueBindEvent('#collapseTxt--btn', 'click', 'app', function (e) {
                if ($(this).parent().hasClass('show')) {
                    $(this).html($(this).data('label-more'));
                } else {
                    $(this).html($(this).data('label-less'));
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-open-modal', 'click', 'app', function (e) {
                e.preventDefault();
                e.stopPropagation();
                let modalBox = $(this).data('target');
                if ($(modalBox).length > 0) {
                    SURPLEX.Bid.toogleBiddingModal(modalBox);
                    new Modal($(modalBox).get(0)).show();
                }
                return false;
            });

            SURPLEX.App.uniqueBindEvent('.js-play-video', 'click', 'app', function (e) {
                let id = $(this).data('id');
                if (id > 0) {
                    let tourItems = document.querySelectorAll('#gallery-main .splide__slide[data-slide-name="virtual_3d_tour_url"]').length;
                    $('#gallery-main-list .splide__slide')[id + tourItems].click();
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-scroll-to-id', 'click', 'app', function (e) {
                let $target= $($(this).data('id'));
                if ($target.length) {
                    if ($target.hasClass('collapse')) {
                        let collapse = Collapse.getOrCreateInstance($target.get(0));
                        if(collapse !== null){
                            collapse.show();
                            e.preventDefault();
                        }
                    }
                    if($target.is('input')){
                        $target.focus();
                    } else if($target.is('#js-bb--hFix')){
                        e.preventDefault();
                        SURPLEX.Track.trackGA(
                            'headStickyTracking',
                            $('meta[name=srxGooglePageType]').attr('content'),
                            'bidButton_clicked'
                        );
                    }
                    window.scroll({top: $target.offset().top - 150, left: 0, behavior: 'smooth'});
                    return false;
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-machine-description a', 'click', 'app', function (e) {
                let url = $(this).attr('href');
                if (url.indexOf('my.matterport.com/show') >= 0 || url.indexOf('surplexwuelfrath.360touren24.de') >= 0) {
                    SURPLEX.Track.trackGA(
                        'virtualTourTracking',
                        $('meta[name=srxGooglePageType]').attr('content'),
                        'tourClick_diverse'
                    );
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-cookieSettingsLink', 'click', 'app', function (e) {
                $('.js-consentText').addClass('d-none');
                $('.js-cookieSettings').removeClass('d-none');

            });

            SURPLEX.App.uniqueBindEvent('.js-cookieSettingsLinkBack', 'click', 'app', function (e) {
                $('.js-consentText').removeClass('d-none');
                $('.js-cookieSettings').addClass('d-none');
            });

            SURPLEX.App.uniqueBindEvent('.js-acceptAllBtn', 'click', 'app', function (e) {
                $("input[type=checkbox][name=consent]").each(function(){
                    $(this).prop("checked", true);
                });
                SURPLEX.App.setCookieOfConsentLayer();
            });

            SURPLEX.App.uniqueBindEvent('.js-confirmSelectionBtn', 'click', 'app', function (e) {
                SURPLEX.App.setCookieOfConsentLayer();
            });

            SURPLEX.App.uniqueBindEvent('.cardContact__contactToggle-js', 'click', 'app', function (e) {
                $(this).closest('.cardContact__contactContainer')
                    .find('.cardContact__contactInfo')
                    .addClass('cardContact__contactInfo--show');
                $(this).toggleClass('cardContact__contactToggle--hidden');
            });
        },

        setCookieOfConsentLayer: function () {
            let $checkedConsent = $("input[name=consent]:checked");
            let $filename = 'all_s1';
            if($checkedConsent.length === 1){
                $filename = 'optout';
            } else if($checkedConsent.length < 5){
                $filename = 'individual';
            } else if($('.js-consentText').hasClass('d-none')){
                $filename = 'all_s2';
            }
            $('#srplx-page').append('<img src="/assets/fixed/cl/' + $filename + '.gif" />');
            let selectedTracking = cSelected();
            if(SURPLEX.Utils.setCookie('bcConsent', selectedTracking) && SURPLEX.Utils.setCookie("bc", "false", 86400000)){
                if (selectedTracking.indexOf(",") > -1 && typeof dataLayer !== "undefined") {
                    dataLayer.push({'event': 'consentSet', 'consent': selectedTracking});
                }
                Modal.getInstance(document.getElementById('consentModal')).hide();
            } else {
                $('.js-cookies-hint').removeClass('d-none');
            }

            if($checkedConsent.length > 1){
                // load GTM if more than functional consent is set
                $.getScript("//www.googletagmanager.com/gtm.js?id=" + googleTagManagerId, function () {
                    dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                });
            }

            function cSelected(){
                let cVal = [];
                $checkedConsent.each(function(){
                    cVal.push($(this).val());
                });
                return cVal.join(',');
            }
        },

        loginSubmitted: function (targetObject, data) {
            if (data.hasOwnProperty('success') && data.success === true) {
                let logInModal = Modal.getInstance(document.getElementById('loginModal'));
                if(logInModal !== null){
                    logInModal.hide();
                }
                SURPLEX.Ajax.addLoadingToTarget($('.js-login-btn'));
                if(typeof SURPLEX.App.loginRedirect === 'function'){
                    SURPLEX.App.loginRedirect();
                } else {
                    window.location.reload(true);
                }
                return;
            } else if (data.hasOwnProperty('html')) {
                if (data.hasOwnProperty('type')) {
                    SURPLEX.FormValidationErrorTracking.trackFormErrorMap(
                        $('#loginform').data('formtype'),
                        'global',
                        '',
                        data.type
                    );
                }
                if (data.hasOwnProperty('lightbox')) {
                    let logInModal = Modal.getInstance(document.getElementById('loginModal'));
                    if(logInModal !== null){
                        logInModal.hide();
                    }
                    let $newElem = $(data.html);
                    $newElem.appendTo("body");
                    new Modal($newElem.get(0)).show();
                    $newElem.get(0).addEventListener('hidden.bs.modal', function(){
                        $newElem.remove();
                    });
                } else {
                    $('.js-statusmessage').addClass('is-invalid').removeClass('is-hidden').html(data.html).show();
                }
                $("input[type='password']").val('');
            }
            $('.loginform').find('input[type="submit"], button[type="submit"]').removeAttr('disabled');
        },

        enableContentLazyLoad: function () {

            let llStack = document.querySelectorAll('[data-surplexlazy]');
            if (llStack.length > 0) {
                if (!('IntersectionObserver' in window)) {
                    Array.prototype.forEach.call(llStack, function (llElem) {
                        loadElement(llElem);
                    });
                } else {
                    loadLazyItems(llStack);
                }
            }

            function loadLazyItems(llStack) {
                let observer = new IntersectionObserver(function (entries) {
                    Array.prototype.forEach.call(entries, function (entry) {
                        if (entry.isIntersecting) {
                            observer.unobserve(entry.target);
                            loadElement(entry.target)
                        }
                    });
                }, {
                    root: null,
                    rootMargin: '0px',
                    threshold: [0]
                });

                Array.prototype.forEach.call(llStack, function (llElem) {
                    observer.observe(llElem);
                });
            }

            function loadElement(llElem) {
                let loadId = llElem.getAttribute('data-surplexlazy');
                if (loadId < 1) {
                    return;
                }
                llElem.removeAttribute("data-surplexlazy")
                SURPLEX.Ajax.fetch(
                    {
                        url: '/',
                        data: {
                            id: loadId,
                            r: "Page/static-page/content",
                            language: SURPLEX.App.pageLanguage
                        },
                        targetObj : $(llElem),
                        loadingTarget : $(llElem)
                    }
                )
            }
        },

        enableImageLazyLoad: function () {
            $("img.lazy:not(.loaded)").lazyload({src: "data-original"});
        },

        changeSVGtoPNGforIE: function () {
            if(!!window.MSInputMethodContext && !!document.documentMode) {
                $("img.js-svg").each(function(index, element) {
                    element.src = 'https://res.surplex.com/assets/surplex.png';
                })
            }
        },

        enableDataRedirect: function () {
            SURPLEX.App.uniqueBindEvent('[data-redirect]', 'click', 'app', function (e) {
                if( e.target && (
                    e.target.className.indexOf('icon__bookmark') > -1 ||
                    e.target.className.indexOf('js-article--watchtool') > -1  ||
                    e.target.className.indexOf('js-article-address') > -1 )
                    ){
                    return;
                }
                let $clickedElem = $(this);
                if($clickedElem.parents('[data-redirect]').length > 0){
                    e.stopPropagation();
                }
                let redirectLink = SURPLEX.Utils.decodeBase($(this).data('redirect'));
                if (typeof $clickedElem.data('track-category') !== "undefined") {
                    if (typeof $clickedElem.data('track-use-pagetype') !== "undefined") {
                        let postFix = '';
                        if($clickedElem.data('track-action-postfix')){
                            postFix = '_' + $clickedElem.data('track-action-postfix');
                        }
                        SURPLEX.Track.trackGA(
                            $clickedElem.data('track-category'),
                            $('meta[name=srxGooglePageType]').attr('content') + postFix,
                            $clickedElem.data('track-label')
                        );
                    } else {
                        SURPLEX.Track.trackGA(
                            $clickedElem.data('track-category'),
                            $clickedElem.data('track-action'),
                            $clickedElem.data('track-label')
                        );
                    }
                }
                if ($clickedElem.is('.js-switch-language')) {
                    let redirectUrl = new URL(window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '') + '' + redirectLink);
                    let currentUrl = new URL(window.location.href);
                    if (currentUrl.searchParams.toString() !== redirectUrl.searchParams.toString()) {
                        let params = currentUrl.searchParams;
                        params.forEach(function(value, key){
                            redirectUrl.searchParams.set(key, value);
                        });
                    }
                    redirectLink = redirectUrl.href;
                }
                if (e.which === 2 || typeof $clickedElem.data('target') !== "undefined"
                    || (e.ctrlKey || e.metaKey)) {
                    window.open(redirectLink, typeof $clickedElem.data('target') !== "undefined" ? $clickedElem.data('target') : '_blank');
                } else {
                    if ($clickedElem.is('.js-download')) {
                        if (!$clickedElem.hasClass('is-loading')) {
                            $clickedElem.addClass('is-loading');
                            $.fn.surplexDownloadFile({'url': redirectLink});
                        }
                    } else {
                        // Add slight delay so that other events have a chance to fire.
                        window.setTimeout(function () {
                            window.location.href = redirectLink;
                        }, 50);
                    }
                }
            });

            SURPLEX.App.uniqueBindEvent('[data-redirect]', 'mousedown', 'app', function (e) {
                if(e.which === 3 && $(this).prop("tagName") === 'SPAN'){
                    if($(this).parent().prop("tagName") !== 'A'){
                        SURPLEX.App.replaceFakeLinks($(this));
                    }
                }
                if (e.which === 2 && ($(this).prop("tagName") === 'SPAN' || $(this).prop("tagName") === 'DIV')) {
                    window.open(SURPLEX.Utils.decodeBase($(this).data('redirect')), '_blank');
                }
            });
        },


        replaceFakeLinks: function ($el) {
            let redirectLink = SURPLEX.Utils.decodeBase($el.data('redirect'));
            $el.off('click').removeAttr('data-redirect');
            let attrs = {};

            $.each($el.get(0).attributes, function (index, attribute) {
                if (typeof attribute !== 'undefined' && attribute.hasOwnProperty('name')) {
                    attrs[attribute.name] = attribute.value;
                    $el.removeAttr(attribute.name);
                }
            });
            $.extend(attrs, {
                'href': redirectLink,
                'rel': 'nofollow noopener',
                'target': typeof $el.data('target') !== "undefined" ? $el.data('target') : '_top'
            });
            let $newEl = $('<a/>');
            $.each(attrs, function (attrKey, attrVal) {
                $newEl.attr(attrKey, attrVal)
            });
            $el.wrap($newEl);
        },

        checkWindowHashString: function () {
            if (location.hash) {
                let $elm = $(location.hash);
                if ($elm.length > 0) {
                    if ($elm.hasClass('collapse')) {
                        let collapse = Collapse.getOrCreateInstance($elm.get(0));
                        if(collapse !== null){
                            collapse.show();
                        }
                    } else if ($elm.hasClass('modal')) {
                        const curTab = Modal.getOrCreateInstance($elm.get(0));
                        if (curTab !== null) {
                            curTab.show();
                        }
                    } else if ($elm.hasClass('tab-pane')) {
                        let $tabTrigger = $('#' + $elm.attr('aria-labelledby'));
                        if ($tabTrigger.length > 0 ) {
                            const curTab = Tab.getOrCreateInstance($tabTrigger.get(0));
                            if (curTab !== null) {
                                curTab.show();
                            }
                        }
                    }
                }
            }
        },

        setActiveTabToCenter: function(){
            document.querySelectorAll('.nav-tabs .nav-link').forEach(tab => {
                tab.addEventListener('show.bs.tab', event => scrollToActiveTab(event.target));
            });

            let activeTab = document.querySelector('.nav-tabs .nav-link.active');
            if (activeTab) scrollToActiveTab(activeTab);

            function scrollToActiveTab(activeTab) {
                let navTabsContainer = document.getElementById('nav-tab');
                if (navTabsContainer !== null) {
                    let scrollToPosition = activeTab.offsetLeft - (navTabsContainer.offsetWidth / 2) + (activeTab.offsetWidth / 2);
                    navTabsContainer.scrollTo({left: scrollToPosition, behavior: 'smooth'});
                }
            }
        },

        initializeImagesRotation: function () {
            let $imageToRotate = $(this.imagesToRotateClass);
            if ($imageToRotate.length > 0) {
                if (SURPLEX.Utils.isTouchDevice()) {
                    $imageToRotate.imagesRotation({intervalFirst: 10});
                    $('.js-article-watch--item, .js-aucCalendar--item').on('touchmove', function (e) {
                        if ($(this).data('rotating') === 1 || $(this)
                            .find(SURPLEX.App.imagesToRotateClass).data('rotating') === 1) {
                            return;
                        }
                        $imageToRotate.filter('[data-rotating="1"]').each(function(){
                            $(this).data('rotating', 0).get(0).dispatchEvent(new MouseEvent('mouseout'))
                        });
                        $(this)
                            .data('rotating', 1)
                            .find(SURPLEX.App.imagesToRotateClass).data('rotating', 1)
                            .get(0).dispatchEvent(new MouseEvent('mouseover'));
                    }).on('touchend', function () {
                        $(this).data('rotating', 0);
                    });
                } else {
                    $imageToRotate.imagesRotation();
                }
            }
        },

        addMobileLanguageSwitch: function () {
            let $mobLangSwitch = $(".js-language-switch");
            if ($mobLangSwitch.length > 0) {
                let nthList = [];
                $('.navigation__languageSelection').first().find('.js-switch-language').each(function () {
                    let $option = $('<option/>').attr({
                        'value': $(this).data('redirect'),
                        'data-language': $(this).data('language')
                    }).text($(this).attr('title'));
                    if ($(this).hasClass('active')) {
                        $option.attr('selected', 'selected');
                    }
                    nthList.push($option);
                });
                let $select = $('<select />').attr({
                    'class': 'language-switcher',
                    'id' : 'language-switcher'
                }).on('change', function () {
                    SURPLEX.Track.trackGA(
                        'languageSelector',
                        $('meta[name=srxGooglePageType]').attr('content'),
                        'from_' + SURPLEX.App.pageLanguage.toUpperCase() + ' to_' +
                        $(this.options[this.selectedIndex]).data('language').toUpperCase()
                    );
                    window.location.href = SURPLEX.Utils.decodeBase(this.options[this.selectedIndex].value);
                });
                $mobLangSwitch.append($select.append(...nthList));
            }
        },

        addMobileCurrencySwitch: function () {
            let $mobCurrencySwitch = $(".js-currency-switch-mobile");
            if ($mobCurrencySwitch.length > 0  && this.pageCurrency !== 'EUR') {
                $mobCurrencySwitch.first().text(this.pageCurrency);
            }
        },



        backToTop: function () {
            let $quickBtns = $(".qbtnCon");

            let $toTop = $('<div/>').attr({
                id:"return-to-top",
                class:"js-track-generic qbtnCon--item icon__chevron-up btn btn--tertiary",
                role: "button",
                title: $quickBtns.attr('data-totop-title')
            }).on('click', function (e) {
                    e.preventDefault();
                    window.scroll({top: 0, left: 0, behavior: 'smooth'});
            });
            $quickBtns.prepend($toTop);
            let $body = $("body");
            const NAV_SHRINKED_CLASS = 'body--navigation--hide';

            function scrollTrigger(callBack) {
                let last_known_scroll_position = 0;
                let ticking = false;
                window.addEventListener("scroll", function () {
                    let previous_known_scroll_position = last_known_scroll_position;
                    last_known_scroll_position = window.pageYOffset;
                    if (!ticking) {
                        window.requestAnimationFrame(function () {
                            callBack(last_known_scroll_position, previous_known_scroll_position);
                            ticking = false;
                        });
                        ticking = true;
                    }
                });
            }

            scrollTrigger((scrollPos, previousScrollPos) => {
                if(scrollPos > 400){
                    if(!$toTop.hasClass('show')){
                        $toTop.addClass('show');
                    }
                    if (previousScrollPos > scrollPos) {
                        if($body.hasClass(NAV_SHRINKED_CLASS)){
                            $body.removeClass(NAV_SHRINKED_CLASS);
                        }
                    } else {
                        if(!$body.hasClass(NAV_SHRINKED_CLASS)){
                            $body.addClass(NAV_SHRINKED_CLASS);
                        }
                    }
                } else {
                    if($toTop.hasClass('show')){
                        $toTop.removeClass('show');
                    }
                    if (previousScrollPos < scrollPos) {
                        if($body.hasClass(NAV_SHRINKED_CLASS)){
                            $body.removeClass(NAV_SHRINKED_CLASS);
                        }
                    }
                }
            });
        },

        loadProfileBadges: function () {
            if (SURPLEX.App.isGuest) {
                return;
            }
            SURPLEX.Ajax.fetch({
                url: '/?r=Page/profile/user-notifications',
                callbackEnd: {
                    'badgesLoaded': function (target, data) {
                        const badges = SURPLEX.Utils.isJSON(data);
                        if (badges.hasOwnProperty('count') && (parseInt(badges.count) > 0) || badges.count === '!') {
                            $('#kontoMenu').append($('<span>').attr({'class': 'user__statusBadge'}).text(badges.count > 9 ? '9+' : badges.count));
                            if (badges.hasOwnProperty('menubadges')) {
                                $.each(badges.menubadges, function (key, value) {
                                    if (value > 0 || value === '!') {
                                        $('.js-usermenu .js-' + key).each(function () {
                                            $(this).append(
                                                $('<span>')
                                                    .attr({'class': 'user__statusBadge'})
                                                    .text(value));
                                        })
                                    }
                                });
                            }
                        }
                    }
                }
            });
        },

        loadFlaps: function () {
            const $itemDetails = $("div.js-machine--details.js-article--item");
            if ($itemDetails.length === 0) {
                return true;
            }
            let $flapsNavigationEl = $("#flapsNavigation");
            if ($flapsNavigationEl.data('initialized') !== undefined ) {
                return true;
            }
            const articleID = $itemDetails.data('article-id');
            const salesArt = $itemDetails.data('salesart');
            const collectionId = $itemDetails.data('collection-id');
            SURPLEX.Ajax.fetch(

                {
                    url: SURPLEX.Utils.decodeBase($flapsNavigationEl.data('url')),
                    data: {
                        articleId: articleID,
                        salesArt: salesArt,
                        collectionId: collectionId
                    },
                    callbackEnd : {
                        'flapsLoaded': function (target, data) {
                            $flapsNavigationEl.data("initialized", true);
                            if (data.hasOwnProperty('html') && typeof data.html === 'object') {
                                $.each(data.html, function (key, elhtml) {
                                    let $elementToReplace = $(key);
                                    if ($elementToReplace.length > 0) {
                                        $elementToReplace.each(function () {
                                            $(this).html(elhtml);
                                        });
                                    }
                                });
                                SURPLEX.App.enableDataRedirect();
                            }
                        }
                    }
                }
            );
        },
        loadGallery: function () {
            const $itemDetails = $("div.js-machine--details.js-article--item");
            if ($itemDetails.length === 0) {
                return true;
            }
            let $sliderEl = $("#imageSlider");
            const articleID = $itemDetails.data('article-id');
            SURPLEX.Ajax.fetch(

                {
                    url: SURPLEX.Utils.decodeBase($sliderEl.data('url')),
                    data: {
                        articleId: articleID
                    },
                    callbackEnd : $.extend(
                        {
                            'sliderLoaded': function (target, data) {
                                if (data.hasOwnProperty('html')) {
                                    $sliderEl.replaceWith(data.html);
                                    SURPLEX.DetailView.initSlider();
                                }
                            }
                        },
                        SURPLEX.Ajax.getDefaultEndCallbacks()
                    ),
                }
            );
        },


        logError: function (err) {

        },

        showAjaxException: function (jqXHR, textStatus, errorThrown) {
            let response = jqXHR.hasOwnProperty('responseJSON') ? jqXHR.responseJSON :
                jqXHR.hasOwnProperty('responseText') ? SURPLEX.Utils.isJSON(jqXHR.responseText) : jqXHR;
            if (response.hasOwnProperty('message')) {
                $('.ajax-error-toast').remove();
                $.fn.surplexToast({
                    title : response.title || 'Ooops something went wrong',
                    content: response.message,
                    status: 'error ajax-error-toast'
                });
            }
        },

        getCsrfToken: function () {
            if ($('meta[name="csrf"]').attr("content") !== '1') {
                return '';
            }
            return SURPLEX.App.getCsrfTokenFromCookie();
        },

        getCsrfTokenFromCookie: function () {
            let csrf = SURPLEX.Utils.getCookie(this.csrfCookieName);
            if (csrf !== null && csrf.length > 0) {
                return decodeURIComponent(csrf);
            }
            return csrf;
        },

        // Instead of using:
        //  $(<selector>).on(<event>, function (e) {....
        // We can use now:
        // SURPLEX.App.uniqueBindEvent(<selector>, <event>, <callingClass>, function);
        // This function make the same PLUS additionally it adds a data-attribute to mark this <selector>
        // has an registered <event> for <callingClass>
        uniqueBindEvent: function (selector, event, callingClass, callback) {
            try {
                const dataTag = 'data-unique-bind-' +
                    event.toString().toLowerCase().replace(/[^a-z0-9-]/g, '-') + '-' +
                    callingClass.toString().toLowerCase().replace(/[^a-z0-9-]/g, '-');
                $(selector).each(function () {
                    let elementToBind = $(this).not('[' + dataTag + ']');
                    if (elementToBind.length > 0 && elementToBind instanceof $) {
                        event.split(" ").forEach(function(e){
                            elementToBind.get(0).addEventListener(e, callback);
                        });
                        elementToBind.attr(dataTag, "1");
                    }
                });
            } catch (e) {
                SURPLEX.App.logError('failed to uniqueBindEvent event: ' + event + ' on selector: ' + selector + ' for ' + callingClass + '' + e);
            }
        },

        showToasts: function () {
            let $toasts = $('.toast');
            if ($toasts.length > 0) {
                $toasts.each(function () {
                    let cookieName = $(this).data('srplx-cookie');
                    let cookieDuration = 86400000; // 1 day in ms
                    if (typeof $(this).attr('data-srplx-cookie-due') !== "undefined") {
                        cookieDuration = $(this).data('srplx-cookie-due');
                    }
                    if (cookieName !== '') {
                        let tC = SURPLEX.Utils.getCookie(cookieName);
                        let tV = $(this).data('srplx-cookie-val');
                        if (!(tC && tC.length > 0 && tV === tC)) {
                            let toastEl = $(this).get(0);
                            let toastInstance = Toast.getOrCreateInstance(toastEl);
                            if (toastInstance !== null) {
                                toastInstance.show();
                                toastEl.addEventListener('hide.bs.toast', function(){
                                    SURPLEX.Utils.setCookie(cookieName, tV, cookieDuration);
                                });
                            }
                        }
                    } else {
                        let toastInstance = Toast.getOrCreateInstance($(this).get(0));
                        if (toastInstance !== null) {
                            toastInstance.show();
                        }
                    }
                });
            }
        },

        enableFbOptOut: function() {
            if ('localStorage' in window && window['localStorage'] !== null) {
                let $fbOptOut = $('.js-fb-optout-state');
                if ($fbOptOut.length > 0) {
                    toogleFbOptOut();
                    SURPLEX.App.uniqueBindEvent('.js_fb-optout-remove', 'click', 'app', function (e) {
                        dataLayer.push({'event': 'removefboptout'});
                        localStorage.removeItem("fboptout");
                        toogleFbOptOut();
                        SURPLEX.App.enableFbOptOut();
                    });

                    SURPLEX.App.uniqueBindEvent('.js_fb-optout', 'click', 'app', function (e) {
                        dataLayer.push({'event': 'fboptout'});
                        localStorage.setItem("fboptout", "true");
                        toogleFbOptOut();
                        SURPLEX.App.enableFbOptOut();
                    });

                    function toogleFbOptOut() {
                        $.each($fbOptOut, function () {
                            let $html = "";
                            if (localStorage.getItem('fboptout')) {
                                $html = $(this).data('act-state') + '<span class="link js_fb-optout-remove">' + $(this).data('deact-action') + '</span>';
                            } else {
                                $html = $(this).data('deact-state') + '<span class="link js_fb-optout">' + $(this).data('act-action') + '</span>';
                            }
                            $(this).html($html);
                        });
                    }
                }
            }
        },

        showOldBrowserHint: function () {
            if(browserTooOldForBootstrap()){
                window.atob || (window.atob = function (input) {
                    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
                    var str = String(input).replace(/[=]+$/, '');
                    for (
                        var bc = 0, bs, bf, idx = 0, r = '';
                        bf = str.charAt(idx++);
                        ~bf && (bs = bc % 4 ? bs * 64 + bf : bf,
                        bc++ % 4) ? r += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
                    ) {
                        bf = chars.indexOf(bf);
                    }
                    return r;
                });
                document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', decodeURIComponent(escape(atob(unsupportedBrowserText))));
            }

            function browserTooOldForBootstrap(){
                var N= navigator.appName;
                var UA= navigator.userAgent;
                var temp;
                var browserVersion= UA.match(/(chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
                if(browserVersion && browserVersion[1] == 'Safari' && (temp= UA.match(/version\/([\.\d]+)/i))!= null)
                    browserVersion[2]= temp[1];
                browserVersion= browserVersion? [browserVersion[1], browserVersion[2]]: [N, navigator.appVersion,'-?'];
                return (
                    (browserVersion[0] == 'MSIE' && browserVersion[1] < 11) ||
                    (browserVersion[0] == 'Chrome' && browserVersion[1] < 45) ||
                    (browserVersion[0] == 'Firefox' && browserVersion[1] < 38) ||
                    (browserVersion[0] == 'Safari' && browserVersion[1] < 9)
                );
            }
        },

        getPageLanguage: function() {
            return $('meta[name="language"]').attr("content");
        },

        getPageCurrency: function() {
            return $('meta[name="currency"]').attr("content");
        },

        enableGetListeners: function() {
            let url = new URL(location.href);
            if (url.searchParams.get("bcnewsletter") === "1" && this.isGuest) {
                SURPLEX.Register.showNewsletterSignUpModal();
            }
            return false;
        },

        frameBreak: function() {
            if (top.location !== location) {
                // etracker optimize tryout
                top.location.href = document.location.href ;
            }
        },

        addLinkUrlToFlash: function() {
            SURPLEX.App.addLinkUrl(
                $('.alert-dismissible .js-link-url')
            );
        },

        addLinkUrl: function($linkUrlLinks) {
            $linkUrlLinks.each(function(index, element) {
                let href = $(element).attr('href');
                if (typeof href !== 'undefined' && href !== false) {
                    let glue = '?';
                    if (href.search(/\?/) > -1) {
                        glue = '&';
                    }
                    $(element).attr('href',
                        $(element).attr('href').concat(glue + 'linkUrl=' + window.btoa(window.location.href))
                    );
                }
            })
        }
    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.App);
    }
})($, SURPLEX);
